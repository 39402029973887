import React, { useState } from "react";
import { Popover, Button, Typography, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function CustomPopover() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClick}>
        Delete Task
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
          <InfoIcon color="warning" sx={{ mr: 1 }} />
          <Typography>Are you sure you want to delete this task?</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
          <Button variant="outlined" onClick={handleClose} sx={{ mr: 1 }}>
            No
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Yes
          </Button>
        </Box>
      </Popover>
    </div>
  );
}
