import {
  Alert,
  AlertTitle,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../Components/Loader";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import CustomPopover from "../Components/Popover";
import CustomSnackBar from "../Components/Snackbars";
import CustomDialog from "../Components/CustomDialog";
import CustomDropdown from "../Components/CustomDropdown";
import ContentableDiv from "../Components/ContentableDiv";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { getRandomColor } from "../Global/getRandomColor";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function ComponentsPage() {
  const [personName, setPersonName] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    setPersonName(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : (event.target.value as string[])
    );
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setPersonName((chips) => chips.filter((chip) => chip !== value));

    // setPersonName(
    //   typeof value === "string" ? value.split(",") : (value as string[])
    // );
  };

  function createData(name: any, Date: any, tags: any, Actions: any) {
    return { name, Date, tags, Actions };
  }

  const rows = [
    createData("Ali Ahmad", "9/13 07:58 PM", ["Client"], 6.0),
    createData("Shaheer", "9/14 09:58 PM", ["Purpose"], 9.0),
    createData("Iqbal", "9/15 01:58 PM", ["Demo"], 16.0),
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set a timer to remove skeleton and display text after 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    // Clear timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Container maxWidth={"md"}>
        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Buttons</Typography>
          <Stack flexDirection={"row"} gap={2}>
            <Button startIcon={<FileUploadIcon />}>Add Files</Button>
            <Button variant="contained">Button</Button>
            <Button variant="outlined" startIcon={<DeleteIcon />}>
              Button
            </Button>
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Icon Buttons with tooltips</Typography>
          <Stack flexDirection={"row"} gap={2}>
            <Tooltip title="Delete" arrow>
              <IconButton aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <IconButton aria-label="delete" color="secondary">
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Chips</Typography>
          <Stack flexDirection={"row"} gap={2}>
            <Chip label="Success" color="success" variant="outlined" />
            <Chip label="Error" color="error" variant="outlined" />
            <Chip label="Secondary" color="secondary" variant="outlined" />
            <Chip label="Warning" color="warning" variant="outlined" />
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Tags</Typography>
          <Stack flexDirection={"row"} gap={2}>
            <Chip label="Success" color="success" variant="filled" />
            <Chip label="Error" color="error" variant="filled" />
            <Chip label="Secondary" color="secondary" variant="filled" />
            <Chip label="Warning" color="warning" variant="filled" />
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Loaders</Typography>
          <Stack flexDirection={"row"} gap={2}>
            <CircularProgress />
            <Loader />
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Skeleton Loading </Typography>
          <Stack flexDirection={"row"} gap={2}>
            {loading ? (
              <Skeleton variant="rectangular" width={210} height={118} />
            ) : (
              <p>Text</p>
            )}
          </Stack>
          {/* <Stack flexDirection={"row"} gap={2}>
          <p>Text</p>
          <Skeleton variant="rectangular" width={210} height={118} />
        </Stack> */}
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Multiple Select</Typography>
          <Stack flexDirection={"row"} gap={2}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Select</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        //   className={classes.chip}
                        onDelete={(e) => handleDelete(e, value)}
                        onClick={() => console.log("clicked chip")}

                        //   onDelete={handleDelete(value)} // Adding the delete icon and functionality
                      />
                    ))}
                  </Box>
                )}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Pagination</Typography>
          <Pagination count={10} color="primary" />
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Bread Crums</Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/material-ui/getting-started/installation/"
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="text.primary"
              href="/material-ui/react-breadcrumbs/"
              aria-current="page"
            >
              About
            </Link>
          </Breadcrumbs>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Table</Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Tags</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, idx) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.Date}</TableCell>
                    <TableCell align="center">
                      <Chip
                        label={row.tags}
                        variant="filled"
                        color={getRandomColor()}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Alerts</Typography>
          <Stack gap={2} flexDirection={"row"}>
            <Alert severity="warning" icon={<InfoIcon />}>
              <AlertTitle>Warning</AlertTitle>
              This is a warning description
            </Alert>
            <Alert severity="error" icon={<CancelIcon />}>
              <AlertTitle>Error</AlertTitle>
              This is a error description
            </Alert>
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Popover</Typography>
          <Stack gap={2} flexDirection={"row"}>
            <CustomPopover />
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>SnackBars</Typography>
          <Stack gap={2} flexDirection={"row"}>
            <CustomSnackBar />
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Dialog</Typography>
          <Stack gap={2} flexDirection={"row"}>
            <CustomDialog />
          </Stack>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Typography mb={2}>Dropdowns</Typography>
          <Stack gap={2} flexDirection={"row"}>
            <CustomDropdown />
          </Stack>
        </Container>
      </Container>
    </>
  );
}
