import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSignUpMutation } from "../Services/api";
import Alert from "@mui/material/Alert";
import OtpConfirmation from "../Components/EmailOtpConfirmation";
import { getErrorMessage } from "../Global/utils";

export default function SignUp() {
  const navigate = useNavigate();
  const [signup, { isLoading, error, isSuccess, data }] = useSignUpMutation();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    setDialogOpen(isSuccess || false);
  }, [isSuccess]);

  const validationSchema = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNo: "+1",
      twoFactorEnabled: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await signup({ ...values }).unwrap();
      } catch (err) {}
    },
  });

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ minHeight: "100vh", pb: 2, display: "flex", alignItems: "center" }}
    >
      <CssBaseline />
      <OtpConfirmation
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        sendTo={formik.values.email}
        title="Email Verification"
        otpPurpose={1}
        afterConfirmation={() => navigate("/signIn")}
        userId={data?.uid}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Box>
          <Avatar
            src="/logo.png"
            sx={{
              borderRadius: "0",
              height: "100px",
              width: "170px",
              scale: "1.5",
            }}
          />
        </Box>

        {error && (
          <>
            <br></br>
            <Alert severity="error" sx={{ width: "100%", mb: 1 }}>
              {getErrorMessage(error)}
            </Alert>
          </>
        )}

        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{ mt: 1, width: "100%" }}
        >
          <Stack gap={2} width={"100%"}>
            <Typography variant="h4" mb={0.5}>
              Sign Up
            </Typography>

            <Stack flexDirection={"row"} gap={2}>
              <TextField
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="given-name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Stack>

            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email
                  ? formik.errors.email ||
                    (formik.values.email &&
                    [
                      "gmail.com",
                      "yahoo.com",
                      "hotmail.com",
                      "outlook.com",
                    ].includes(formik.values.email.split("@")[1])
                      ? "Please use your work email"
                      : "")
                  : ""
              }
            />

            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />

            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    name="twoFactorEnabled"
                    checked={formik.values.twoFactorEnabled}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Do you want to enable two factor authentication?"
              />

              {formik.values.twoFactorEnabled === true ? (
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  name="phoneNo"
                  label="Phone Number"
                  type="text"
                  id="phoneNo"
                  placeholder="+1 343-233-4453"
                  value={formik.values.phoneNo || ""}
                  onChange={formik.handleChange}
                />
              ) : null}

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" required />}
                label="I agree to the terms and policy."
              />
            </Stack>

            <Stack flexDirection={"row"} justifyContent={"flex-end"}>
              <Link
                variant="body2"
                underline="hover"
                color={"secondary"}
                sx={{
                  fontWeight: "bold",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate("/signIn")}
              >
                Already have an account? Sign in
              </Link>
            </Stack>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              // sx={{ mt: 3, mb: 2 }}
              // color="primary"
              loading={isLoading}
            >
              Sign Up
            </LoadingButton>
            {/* <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  href="#"
                  underline="hover"
                  variant="body2"
                >
                 
                </Link>
              </Grid>
            </Grid> */}
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
