import React from "react";
import Navbar from "../Components/NavBar";


export default function Dashboard() {

  return (
    <>
      <Navbar/>
       
    </>
  );
}


