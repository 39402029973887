import PropTypes from "prop-types";

import { createContext, useContext, useEffect, useMemo, useState } from "react";
// @mui
import { CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
  alpha,
} from "@mui/material/styles";
//
import shadows from "./shadows";
import typography from "./typography";
import GlobalStyles from "./globalStyles";
import customShadows from "./customShadows";
import componentsOverride from "./overrides";

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

export default function ThemeProvider({ children }) {
  const storedColor = "#37B67C";

  const reducedOpacityColor = `rgba(${parseInt(
    storedColor.slice(-6, -4),
    16
  )}, ${parseInt(storedColor.slice(-4, -2), 16)}, ${parseInt(
    storedColor.slice(-2),
    16
  )}, 0.08)`;

  const reducedOpacityColor1 = `rgba(${parseInt(
    storedColor.slice(-6, -4),
    16
  )}, ${parseInt(storedColor.slice(-4, -2), 16)}, ${parseInt(
    storedColor.slice(-2),
    16
  )}, 0.16)`;

  const secondaryColor = "#0D1F5B";
  const tertiaryColor = "#242A2B";

  const PRIMARY = {
    lighter: "#D1E9FC",
    light: "#76B0F1",
    main: storedColor,
    dark: "#103996",
    darker: "#061B64",
    contrastText: "#fff",
  };

  const SECONDARY = {
    lighter: "#D6E4FF",
    light: "#84A9FF",
    main: secondaryColor,
    dark: "#1939B7",
    darker: "#091A7A",
    contrastText: "#fff",
  };

  const themeOptions = useMemo(
    () => ({
      palette: {
        common: { black: "#000", white: "#fff" },
        primary: PRIMARY,
        secondary: SECONDARY,
        info: INFO,
        success: SUCCESS,
        warning: WARNING,
        error: ERROR,
        grey: GREY,
        divider: alpha(GREY[500], 0.24),
        text: {
          primary: GREY[800],
          secondary: GREY[600],
          disabled: GREY[500],
        },
        background: {
          paper: "#fff",
          default: GREY[100],
          neutral: GREY[200],
        },
        action: {
          active: storedColor,
          selected: reducedOpacityColor,
          hover: alpha(GREY[500], 0.08),
          activeHover: reducedOpacityColor1,
          disabled: alpha(GREY[500], 0.8),
          disabledBackground: alpha(GREY[500], 0.24),
          focus: alpha(GREY[500], 0.24),
          hoverOpacity: 0.08,
          disabledOpacity: 0.48,
          tertiaryColor,
        },
      },
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [storedColor, reducedOpacityColor, reducedOpacityColor1]
  );

  const theme = useMemo(() => createTheme(themeOptions), [themeOptions]);

  theme.components = componentsOverride(theme);

  return (
    <ThemeContext.Provider value={theme}>
      <StyledEngineProvider injectFirst>
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </MUIThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
}
