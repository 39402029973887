// ----------------------------------------------------------------------

export default function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        filledInfo: {
          backgroundColor: 'rgba(255, 171, 0, 0.16)',
          color: 'rgb(183, 110, 0)',
          padding: '0px 12px',
          fontSize: '0.75rem',
          fontWeight: 700,
          height: '24px',
          minWidth: '24px',
          borderRadius: '6px',
          '& .MuiChip-label': {
            padding: '0px',
          },
        },
        filledSuccess: {
          backgroundColor: 'rgba(34, 197, 94, 0.16)',
          color: 'rgb(17, 141, 87)',
          padding: '0px 12px',
          fontSize: '0.75rem',
          fontWeight: 700,
          height: '24px',
          minWidth: '24px',
          borderRadius: '6px',
          '& .MuiChip-label': {
            padding: '0px',
          },
        },
        filledError: {
          backgroundColor: 'rgba(255, 86, 48, 0.16)',
          color: 'rgb(183, 29, 24)',
          fontSize: '0.75rem',
          fontWeight: 700,
          padding: '0px 12px',

          height: '24px',
          minWidth: '24px',
          borderRadius: '6px',
          '& .MuiChip-label': {
            padding: '0px',
          },
        },
        filledWarning: {
          backgroundColor: 'rgba(145, 158, 171, 0.16)',
          color: 'rgb(99, 115, 129)',
          fontSize: '0.75rem',
          padding: '0px 12px',

          fontWeight: 700,
          height: '24px',
          minWidth: '24px',
          borderRadius: '6px',
          '& .MuiChip-label': {
            padding: '0px',
          },
        },
        filledSecondary: {
          backgroundColor: 'rgba(0, 184, 217, 0.16)',
          color: 'rgb(0, 108, 156)',
          padding: '0px 12px',
          fontSize: '0.75rem',
          fontWeight: 700,
          height: '24px',
          minWidth: '24px',
          borderRadius: '6px',
          '& .MuiChip-label': {
            padding: '0px',
          },
        },
      },
    },
  };
}
