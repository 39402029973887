import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useChangePasswordMutation } from "../Services/api";
import Alert from "@mui/material/Alert";
import { getErrorMessage } from "../Global/utils";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth } from "../Context/authContext";
import Navbar from "../Components/NavBar";
import { Stack } from "@mui/material";

export default function ChangePassword() {
  const [changePassword, { isLoading, error, isSuccess }] =
    useChangePasswordMutation();
  const { user } = useAuth();

  const validationSchema = yup.object({
    currentPassword: yup.string().required("Old Password is required"),
    newPassword: yup.string().required("New Password is required"),
    confirmNewPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("newPassword")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await changePassword({ ...values, uid: user?.id }).unwrap();
      } catch (err) {}
    },
  });

  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="xs" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          {error && (
            <>
              <Alert severity="error" sx={{ width: "100%", mb: 1 }}>
                {getErrorMessage(error)}
              </Alert>
            </>
          )}

          {isSuccess && (
            <>
              <Alert severity="success" sx={{ width: "100%", mb: 1 }}>
                {"Password changed successfully"}
              </Alert>
            </>
          )}

          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Stack gap={2} width={"100%"}>
              <Typography variant="h4" mb={0.5}>
                Change Password
              </Typography>
              <TextField
                required
                fullWidth
                name="currentPassword"
                label="Old Password"
                type="password"
                id="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                }
                helperText={
                  formik.touched.currentPassword &&
                  formik.errors.currentPassword
                }
              />
              <TextField
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              />

              <TextField
                required
                fullWidth
                name="confirmNewPassword"
                label="Confirm New Password"
                type="password"
                id="confirmNewPassword"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmNewPassword &&
                  Boolean(formik.errors.confirmNewPassword)
                }
                helperText={
                  formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword
                }
              />

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                // sx={{ mt: 3, mb: 2 }}

                loading={isLoading}
              >
                Change Password
              </LoadingButton>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
}
