import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {  SerializedError } from '@reduxjs/toolkit/';
 
 

function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
    return error && typeof error === 'object' && 'data' in error;
  }
  
function isSerializedError(error: any): error is SerializedError {
return error && typeof error === 'object' && 'message' in error;
}
  
const getErrorMessage = (error: FetchBaseQueryError | SerializedError | undefined): string => {
if (error) {
    if (isFetchBaseQueryError(error)) {
    return (error.data && typeof error.data === 'object' && 'error' in error.data && typeof error.data.error === 'string')
        ? error.data.error
        : 'An unknown error occurred.';
    } else if (isSerializedError(error)) {
    return typeof error.message === 'string' ? error.message : 'An unknown error occurred.';
    }
}
return 'An unknown error occurred.';
};


export {getErrorMessage};