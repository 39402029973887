import { Container, Stack, Typography } from "@mui/material";
import ContentableDiv from "../Components/ContentableDiv";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

const TaskPane = () => {
  const [displayedText, setDisplayedText] = useState(""); // Gradual text display ke liye
  const [typingComplete, setTypingComplete] = useState(false);

  //   useEffect(() => {
  //     window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  //   }, [displayedText, typingComplete]);

  return (
    <Container maxWidth={"md"} component={"main"}>
      {/* <Container sx={{ pb: 2, mt: 2 }}> */}
      <Typography my={2}>Task Pane</Typography>
      <Stack gap={2} flexDirection={"row"} pb={2}>
        <ContentableDiv
          displayedText={displayedText}
          setDisplayedText={setDisplayedText}
          typingComplete={typingComplete}
          setTypingComplete={setTypingComplete}
        />
      </Stack>
      {/* </Container> */}
    </Container>
  );
};

export default TaskPane;
