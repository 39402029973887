import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useResendOtpMutation, useVerifyOtpMutation } from '../Services/api';
import Alert from '@mui/material/Alert';
import { getErrorMessage } from '../Global/utils';
import { Dialog, Card, CardHeader, IconButton, Paper, CardContent,  Stack, Button } from '@mui/material';




export default function EmailOtpConfirmation(
    { dialogOpen, setDialogOpen, sendTo, title, otpPurpose, afterConfirmation, userId = null}: 
    {dialogOpen: boolean; setDialogOpen: (open: boolean) => void; sendTo: string; title: string; otpPurpose: number; afterConfirmation: () => void; userId: null | string}
  )
{
  const [verifyOtp, { isLoading, error, isSuccess, isError, data }] = useVerifyOtpMutation();
  const [resendOtp, resendOtpObj] = useResendOtpMutation();

  const [otp, setOTP] = React.useState('');
  const [verified, setVerified] = React.useState(false);
  const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);
  const [resendCountdown, setResendCountdown] = React.useState(0);

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (resendCountdown > 0) {
      intervalId = setInterval(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [resendCountdown]);

  React.useEffect(() => {
    if (isSuccess)
    {
      afterConfirmation();
      localStorage.setItem('resetPasswordId', data?.userId)
    }
   
  }, [isSuccess]);


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (index: number, value: string) => {
    const newOTP = otp.substr(0, index) + value + otp.substr(index + 1);
    setOTP(newOTP);
  
    if (value && index < 5 && inputRefs.current[index + 1] !== null) {
      inputRefs.current[index + 1]?.focus();
    }
  };


  const verifyOtpSubmit = async () => {

    try {
      await verifyOtp({ otp, otpPurpose, uid: userId, email: sendTo }).unwrap();
      
    } catch (err) {
     
    }
  };

  const resendOtpSubmit = async () => {

    try {
      await resendOtp({ otpPurpose, uid: userId, email: sendTo }).unwrap();
      
    } catch (err) {
     
    }
  };

  const value = 0;



  return (
    <Dialog
    open={dialogOpen}
    PaperProps={{
      sx: { borderRadius: '9px' }
    }}
    // onClose={!resultStats2?.isSuccess ? handleCloseDialog : undefined}
    maxWidth="xs"
    fullWidth
  >
    <Card component={Paper}>
      {/* {!resultStats2?.isSuccess && ( */}
      {true && (
        <CardHeader
          action={
            <IconButton color="secondary" aria-label="close" onClick={handleCloseDialog}>
              {/* <Iconify icon="eva:close-fill" /> */}
            </IconButton>
          }
        />
      )}

      <CardContent>
        {isLoading? (
          <Typography variant="subtitle1" textAlign={'center'}>
            {'Loading'}
          </Typography>
        ) : (
          !verified && (
            <>
          
          <Typography variant="subtitle1" textAlign={'center'} mb={2} fontWeight="bold">
            {title}
          </Typography>
              {isError && (
                <>
                  <Alert severity="error" sx={{width: "100%"}}>
                   {getErrorMessage(error)}
                  </Alert>
                  <br></br>
                </>
              )}

              <Typography textAlign={'center'} mb={2}>
                Your 6-digit PIN has been sent to {sendTo}
              </Typography>
              <Stack gap={2} flexDirection={'row'} justifyContent={'center'}>
                {[...Array(6)].map((_, index) => (
                  <Box key={index}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                      value={otp[index] || ''}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      // eslint-disable-next-line no-return-assign
                      inputRef={(ref) => (inputRefs.current[index] = ref)}
                    />
                  </Box>
                ))}
              </Stack>

              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                color="primary"
                // onClick={handleSubmit((data) => onSubmit(data, false, true))}
                onClick={() => verifyOtpSubmit()}
              >
                Verify
              </Button>

              {resendCountdown === 0 ? (
                <Typography textAlign="center" mt={2}>
                  Haven’t received the verification code yet?{' '}
                  <Typography
                    color="primary"
                    fontWeight="bold"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {setResendCountdown(59); resendOtpSubmit();}}
                  >
                    Resend
                  </Typography>
                </Typography>
              ) : (
                <Typography textAlign="center" mt={2} variant="body2">
                  We’ve sent you your new code. Try again in {resendCountdown} seconds
                </Typography>
              )}
            </>
          )
        )}
      </CardContent>
    </Card>
  </Dialog>
  );
}