export default function Alert(theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius, // You can customize border-radius if needed
          "&.MuiAlert-standardWarning": {
            border: `1px solid ${theme.palette.warning.main}`, // Warning border
          },
          "&.MuiAlert-standardError": {
            border: `1px solid ${theme.palette.error.main}`, // Error border
          },
          "&.MuiAlert-standardSuccess": {
            border: `1px solid ${theme.palette.success.main}`, // Success border
          },
          "&.MuiAlert-standardInfo": {
            border: `1px solid ${theme.palette.info.main}`, // Info border
          },
        },
      },
    },
  };
}
