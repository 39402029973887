import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Navbar from "../Components/NavBar";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../Context/authContext";

import DeleteIcon from "@mui/icons-material/Delete";
import CustomDropdown from "../Components/CustomDropdown";

export default function Setting() {
  const { user } = useAuth();

  console.log(user, "user");

  // const [{ isLoading, error }] = useSignUpMutation();

  const validationSchema = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNo: user?.twoFactorEnabled ? user?.phoneNo : "+1",
      twoFactorEnabled: user?.twoFactorEnabled,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
      } catch (err) {}
    },
  });

  return (
    <>
      <Navbar />
      <Container
        component="main"
        sx={{ height: "100vh", maxWidth: "460px !important" }}
      >
        <CssBaseline />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{ mt: 1, width: "100%" }}
          >
            <Stack gap={2} width={"100%"}>
              <Typography variant="h4" mb={0.5}>
                Profile
              </Typography>

              <Stack flexDirection={"row"} gap={2}>
                <TextField
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="given-name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Stack>

              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                disabled
                type="email"
                autoComplete="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.touched.email
                    ? formik.errors.email ||
                      (formik.values.email &&
                      [
                        "gmail.com",
                        "yahoo.com",
                        "hotmail.com",
                        "outlook.com",
                      ].includes(formik.values.email.split("@")[1])
                        ? "Please use your work email"
                        : "")
                    : ""
                }
              />

              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="twoFactorEnabled"
                      checked={formik.values.twoFactorEnabled}
                      onChange={formik.handleChange}
                      color="primary"
                    />
                  }
                  label="Do you want to enable two factor authentication?"
                />

                {formik.values.twoFactorEnabled === true ? (
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    name="phoneNo"
                    label="Phone Number"
                    type="text"
                    id="phoneNo"
                    placeholder="+1 343-233-4453"
                    value={formik.values.phoneNo || ""}
                    onChange={formik.handleChange}
                  />
                ) : null}
              </Stack>

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                // loading={isLoading}
              >
                Update Profile
              </LoadingButton>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
}
