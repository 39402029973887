import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "../Global/utils";
import {
  useForgetPasswordMutation,
  useResetPasswordMutation,
} from "../Services/api";
import { Grid, Link, Stack } from "@mui/material";
import EmailOtpConfirmation from "../Components/EmailOtpConfirmation";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [forgetPassword, { isLoading, error, isSuccess }] =
    useForgetPasswordMutation();
  const [resetPassword, resetPasswordObj] = useResetPasswordMutation();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [allowReset, setAllowReset] = React.useState(false);

  React.useEffect(() => {
    setDialogOpen(isSuccess || false);
  }, [isSuccess]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    await forgetPassword({ email: data.get("email") as string });
    setEmail(data.get("email") as string);
  };

  const handleSubmitForChangePassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    await resetPassword({
      newPassword: data.get("newPassword") as string,
      uid: localStorage.getItem("resetPasswordId"),
    });
    navigate("/signIn");
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ minHeight: "100vh", mb: 2, display: "flex", alignItems: "center" }}
    >
      <CssBaseline />

      <EmailOtpConfirmation
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        sendTo={email}
        title="Password Reset Verification"
        otpPurpose={2}
        afterConfirmation={() => {
          setDialogOpen(false);
          setAllowReset(true);
        }}
        userId={null}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Box>
          <Avatar
            src="/logo.png"
            sx={{
              borderRadius: "0",
              height: "100px",
              width: "170px",
              scale: "1.5",
            }}
          />
        </Box>
        {error && (
          <>
            <br></br>
            <Alert severity="error" sx={{ width: "100%", mb: 1 }}>
              {getErrorMessage(error)}
            </Alert>
          </>
        )}

        {!allowReset && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Typography variant="h4" mb={0.5}>
              Forget Your Password?
            </Typography>

            <Typography variant="body2" mb={2}>
              Please enter the email address associated with your account and We
              will email you a otp to reset your password.
            </Typography>

            <Stack gap={2} width={"100%"}>
              <TextField
                required
                fullWidth
                name="email"
                label="Email"
                type="email"
                id="email"
              />

              <Stack flexDirection={"row"} justifyContent={"flex-end"}>
                <Link
                  variant="body2"
                  underline="hover"
                  color={"secondary"}
                  sx={{
                    fontWeight: "bold",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/signIn")}
                >
                  Return to Sign in?
                </Link>
              </Stack>

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                loading={isLoading}
              >
                Forget Password
              </LoadingButton>
            </Stack>
          </Box>
        )}

        {allowReset && (
          <Box
            component="form"
            onSubmit={handleSubmitForChangePassword}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmNewPassword"
              label="Confirm New Password"
              type="password"
              id="confirmNewPassword"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="primary"
              loading={isLoading}
            >
              Submit
            </LoadingButton>
          </Box>
        )}
      </Box>
    </Container>
  );
}
