import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../Services/api";
import Alert from "@mui/material/Alert";
import { getErrorMessage } from "../Global/utils";
import { useAuth } from "../Context/authContext";
import { Button, Divider, Stack } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

export default function SignIn() {
  const navigate = useNavigate();
  const [login, { isLoading, error }] = useLoginMutation();
  const { login: loginContext } = useAuth();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email") as string | "";
    const password = data.get("password") as string | "";

    try {
      const userData = await login({ email, password }).unwrap();
      loginContext(userData.user, userData.token);

      navigate("/dashboard");
    } catch (err) {}
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ minHeight: "100vh", pb: 2, display: "flex", alignItems: "center" }}
    >
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Box>
          <Avatar
            src="/logo.png"
            sx={{
              borderRadius: "0",
              height: "100px",
              width: "170px",
              scale: "1.5",
            }}
          />
        </Box>

        {error && (
          <>
            <Alert severity="error" sx={{ width: "100%", mb: 1 }}>
              {getErrorMessage(error)}
            </Alert>
          </>
        )}

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          <Stack gap={2} width={"100%"}>
            <Typography variant="h4" mb={0.5}>
              Sign In
            </Typography>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Stack flexDirection={"row"} justifyContent={"flex-end"}>
              <Link
                variant="body2"
                underline="hover"
                color={"secondary"}
                sx={{
                  fontWeight: "bold",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate("/forgetPassword")}
              >
                Forgot password?
              </Link>
            </Stack>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              size="large"
            >
              Sign In
            </LoadingButton>

            <Link
              underline="none"
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 0.5,
                mt: 1,
                cursor: "pointer",
                "&:hover .signUpText": {
                  textDecoration: "underline", // Ensures no underline on hover
                },
              }}
              onClick={() => navigate("/signUp")}
            >
              <Typography>{"Don't have an account?"}</Typography>
              <Typography
                fontWeight={"bold"}
                color={"secondary"}
                className="signUpText"
              >
                Sign Up
              </Typography>
            </Link>

            <Divider>
              <Typography>Or</Typography>
            </Divider>

            <Button
              variant="outlined"
              sx={{ mt: 0.5 }}
              color="secondary"
              startIcon={
                <Box>
                  <img src="./google.png" />
                </Box>
              }
              size="large"
            >
              Sign in with google
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
