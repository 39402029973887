import React, { useState, MouseEvent } from "react";
import { Menu, MenuItem, Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CustomDropd: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentOptions, setCurrentOptions] = useState<string>("main"); // state to switch between options
  const [previousOptions, setPreviousOptions] = useState<string | null>(null); // state to track previous options

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentOptions("main");
    setPreviousOptions(null);
  };

  const goBack = () => {
    setCurrentOptions(previousOptions as string);
    setPreviousOptions(null);
  };

  const handleOptionClick = (nextOptions: string) => {
    setPreviousOptions(currentOptions);
    setCurrentOptions(nextOptions);
  };

  const renderOptions = () => {
    if (currentOptions === "main") {
      return (
        <>
          <MenuItem onClick={() => handleOptionClick("sub1")}>
            Option 1
          </MenuItem>
          <MenuItem onClick={() => handleOptionClick("sub2")}>
            Option 2
          </MenuItem>
          <MenuItem onClick={() => handleOptionClick("sub3")}>
            Option 3
          </MenuItem>
        </>
      );
    } else if (currentOptions === "sub1") {
      return (
        <>
          <MenuItem>
            <IconButton onClick={goBack} size="small">
              <ArrowBackIcon />
            </IconButton>
            <strong>Submenu 1</strong>
          </MenuItem>
          <MenuItem>Sub Option 1</MenuItem>
          <MenuItem>Sub Option 2</MenuItem>
        </>
      );
    } else if (currentOptions === "sub2") {
      return (
        <>
          <MenuItem>
            <IconButton onClick={goBack} size="small">
              <ArrowBackIcon />
            </IconButton>
            <strong>Submenu 2</strong>
          </MenuItem>
          <MenuItem>Sub Option A</MenuItem>
          <MenuItem>Sub Option B</MenuItem>
        </>
      );
    }
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Open Dropdown
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderOptions()}
      </Menu>
    </div>
  );
};

export default CustomDropd;
