import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../Global/config';

const customBaseQuery = () => {
  return fetchBaseQuery({
    baseUrl: config.baseUrl,
    prepareHeaders: (headers) => {
      if (localStorage.getItem('token')) 
        headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  });
};

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (credentials) => ({
        url: '/user/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    signUp: builder.mutation<any, any>({
      query: (data) => ({
        url: '/user/signUp',
        method: 'POST',
        body: data,
      }),
    }),
    forgetPassword: builder.mutation<any, any>({
      query: (data) => ({
        url: '/user/forgetPassword',
        method: 'POST',
        body: data,
      }),
    }),
    changePassword: builder.mutation<any, any>({
      query: (data) => ({
        url: '/user/changePassword',
        method: 'POST',
        body: data,
      }),
    }),
    verifyOtp: builder.mutation<any, any>({
      query: (data) => ({
        url: '/user/verifyOtp',
        method: 'POST',
        body: data,
      }),
    }),
    resendOtp: builder.mutation<any, any>({
      query: (data) => ({
        url: '/user/resendOtp',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation<any, any>({
      query: (data) => ({
        url: '/user/resetPassword',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { 
  useLoginMutation, 
  useSignUpMutation, 
  useVerifyOtpMutation, 
  useResetPasswordMutation,
  useResendOtpMutation,
  useChangePasswordMutation,
  useForgetPasswordMutation 
} = authApi;
export default authApi;
