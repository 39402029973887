import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import ChangePassword from "./Pages/ChangePassword";
import ForgetPassword from "./Pages/ForgetPassword";
import Dashboard from "./Pages/Dashboard";
import { useAuth } from "./Context/authContext";
import Setting from "./Pages/Setting";
import ComponentsPage from "./Pages/ComponentsPage";
import TaskPane from "./Pages/TaskPane";

function App() {
  const { user } = useAuth();

  return (
    <Router>
      <Routes>
        {user ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route path="/setting" element={<Setting />} />
          </>
        ) : (
          <>
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            <Route path="/components" element={<ComponentsPage />} />
            <Route path="/task-pane" element={<TaskPane />} />
          </>
        )}
        <Route
          path="*"
          element={<Navigate to={user ? "/dashboard" : "/signIn"} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
