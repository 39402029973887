const Loader = () => {
  return (
    <img
      src="./spinner.png"
      alt="Loading..."
      width={40}
      height={40}
      style={{
        animation: "spin 2s linear infinite",
      }}
    />
  );
};

export default Loader;
