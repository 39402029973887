import React, { useState, useRef, useEffect } from "react";
import {
  Chip,
  Box,
  Typography,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import { getRandomColor } from "../Global/getRandomColor";
// import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Loader from "./Loader";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import ReplyIcon from "@mui/icons-material/Reply";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function UrlInput({
  displayedText,
  setDisplayedText,
  typingComplete,
  setTypingComplete,
}) {
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }
  }, [copy]);

  const [loader, setLoader] = useState(true); // Loader ke liye initial state true
  const [replyText, setReplyText] = useState("");

  const fullReply = ` aQuaid-e-Azam Muhammad Ali Jinnah, the revered leader and founder of Pakistan, played a pivotal role in the creation of the nation through his steadfast dedication and visionary leadership. Born on December 25, 1876, Jinnah initially pursued a career in law but soon became a prominent figure in the political arena, advocating for the rights of Muslims in the Indian subcontinent. His unwavering commitment to the cause of a separate homeland led to the creation of Pakistan on August 14, 1947. Known for his remarkable oratory skills and political acumen, Jinnah's leadership was instrumental in navigating the complexities of partition and establishing the foundational principles of the new state. His legacy endures as a symbol of integrity and resilience, inspiring generations to uphold the ideals of justice and unity.`;

  useEffect(() => {
    // Loader ko 4 second ke liye dikhana
    const loaderTimer = setTimeout(() => {
      setLoader(false); // Loader ko false kar do

      // Typing effect shuru karo jab loader remove ho
      let currentIndex = 0;
      const typingInterval = setInterval(() => {
        if (currentIndex < fullReply.length - 1) {
          setDisplayedText((prev) => prev + fullReply[currentIndex]);
          currentIndex++;
        } else {
          clearInterval(typingInterval); // Typing complete hone par interval clear karo
          setTypingComplete(true);
        }
      }, 10); // Har 50 milliseconds me ek character add hota hai (adjust as needed)
      // if (textContainerRef.current) {
      //   textContainerRef.current.scrollTop =
      //     textContainerRef.current.scrollHeight;
      // }
    }, 4000); // Loader ke liye 4 second ka delay

    return () => {
      clearTimeout(loaderTimer); // Cleanup timers
    };
  }, []);

  const [chips, setChips] = useState(["http://localhost:3000/components"]);
  const editableDiv = useRef(null);

  const followEditableDiv = useRef(null);

  // Function to maintain the caret position after content updates
  const placeCaretAtEnd = (element) => {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  };

  // Handle typing or text input in the content-editable div
  const handleInput = (e) => {
    placeCaretAtEnd(editableDiv.current); // Keep caret at the end
  };

  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    const handleInputFollow = () => {
      setHasContent(followEditableDiv.current.innerText.trim().length > 0);
    };

    const div = followEditableDiv.current;
    if (div) {
      div.addEventListener("input", handleInputFollow);

      return () => {
        div.removeEventListener("input", handleInputFollow);
      };
    }
  }, []);

  // Function to maintain the caret position after content updates
  // const placeCaretAtEndFollow = (element) => {
  //   const range = document.createRange();
  //   const sel = window.getSelection();
  //   range.selectNodeContents(element);
  //   range.collapse(false);
  //   sel.removeAllRanges();
  //   sel.addRange(range);
  // };

  // // Handle typing or text input in the content-editable div
  // const handleInputFollow = (e) => {
  //   placeCaretAtEndFollow(followEditableDiv.current); // Keep caret at the end
  // };

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          padding: 2,
          width: "100%",
          backgroundColor: "white",
          minHeight: "100px",
          boxShadow: theme.customShadows.z0,
          transition: "box-shadow 0.3s ease",
          "&:focus-within": {
            boxShadow: theme.customShadows.z2,
          },
        })}
      >
        <Stack sx={{ border: "1px solid #e3e3e3", borderRadius: 0.7 }}>
          <Stack
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            pl={2}
            borderBottom={"1px solid #e3e3e3"}
          >
            <Typography variant="subtitle1">Copied</Typography>
            <Stack flexDirection={"row"}>
              <IconButton size="small">
                <CloseIcon fontSize="14" />
              </IconButton>
              <IconButton size="small">
                <ExpandMoreIcon fontSize="14" />
              </IconButton>
            </Stack>
          </Stack>

          <Stack bgcolor={"#f9f9f9"} p={2}>
            <Typography variant="body2">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
              Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
              BC. This book is a treatise on the theory of ethics, very popular
              during the Renaissance.
            </Typography>
          </Stack>
        </Stack>

        <Box my={1}>
          <div
            ref={editableDiv}
            contentEditable
            suppressContentEditableWarning
            onInput={handleInput}
            style={{
              border: "none",
              outline: "none",
              gap: "8px",
              // minHeight: "50px",
              width: "100%",
              whiteSpace: "pre-wrap", // Ensure text wraps
              overflowWrap: "break-word", // Handle long words breaking
              display: "flex",
              flexWrap: "wrap", // Allow wrapping of child elements
              alignItems: "center",
            }}
          >
            {/* Hardcoded text */}
            Write a paragraph on Quaid e Azam&nbsp;
            {/* Display the MUI Chip with the URL */}
            {chips.map((chip, index) => (
              <Chip
                key={index}
                label={chip}
                onDelete={() => setChips(chips.filter((_, i) => i !== index))}
                sx={{ marginRight: 0.5 }}
              />
            ))}
            {/* Highlighted mention after the Chip */}
            <Chip
              label="@Documents:Slide25.JPG"
              color={"info"}
              variant="filled"
            />
          </div>
        </Box>

        <Box mt={1}>
          <Button startIcon={<FileUploadIcon />} variant="outlined">
            Add Files
          </Button>
        </Box>
      </Box>

      <Stack
        mt={3}
        flexDirection={"column"}
        gap={2}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        {!loader && (
          <Box>
            <Typography variant="subtitle1" mb={2}>
              Refrences
            </Typography>
            <Stack gap={2}>
              <Chip label="Wikipedia" variant="filled" color="info" />
              <Chip label="Docs" variant="filled" color="info" />
            </Stack>
          </Box>
        )}
        {loader ? (
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Typography>Thinking...</Typography>
            <Loader />
          </Stack>
        ) : (
          <Typography>{displayedText}</Typography>
        )}{" "}
      </Stack>

      {typingComplete && (
        <Stack direction="row" mt={1.5}>
          <IconButton size="small" onClick={() => setCopy(true)}>
            {copy ? (
              <DoneIcon fontSize="14" />
            ) : (
              <ContentCopyIcon fontSize="14" />
            )}
          </IconButton>
          <IconButton size="small">
            <CheckCircleOutlineIcon fontSize="14" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() =>
              setReplyText(
                "Quaid-e-Azam Muhammad Ali Jinnah, the revered leader and founder of Pakistan, played a pivotal role in the creation of the nation through his steadfast dedication and visionary leadership. Born on December 25, 1876, Jinnah initially pursued a career in law but soon became a prominent figure in the political arena, advocating for the rights of Muslims in the Indian subcontinent. His unwavering commitment to the cause of a separate homeland led to the creation of Pakistan on August 14, 1947. Known for his remarkable oratory skills and political acumen, Jinnah's leadership was instrumental in navigating the complexities of partition and establishing the foundational principles of the new state. His legacy endures as a symbol of integrity and resilience, inspiring generations to uphold the ideals of justice and unity."
              )
            }
          >
            <ReplyIcon fontSize="14" />
          </IconButton>
        </Stack>
      )}

      {typingComplete && (
        <Box
          sx={(theme) => ({
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            padding: 2,
            mt: 3,
            width: "100%",
            minHeight: "100px",
            boxShadow: theme.customShadows.z0,
            transition: "box-shadow 0.3s ease",
            "&:focus-within": {
              boxShadow: theme.customShadows.z2,
            },
          })}
        >
          {replyText && (
            <Stack sx={{ border: "1px solid #e3e3e3", borderRadius: 0.7 }}>
              <Stack
                flexDirection="row"
                justifyContent={"space-between"}
                alignItems={"center"}
                pl={2}
                borderBottom={"1px solid #e3e3e3"}
              >
                <Typography variant="subtitle1">Copied</Typography>
                <Stack flexDirection={"row"}>
                  <IconButton size="small">
                    <CloseIcon fontSize="14" />
                  </IconButton>
                  <IconButton size="small">
                    <ExpandMoreIcon fontSize="14" />
                  </IconButton>
                </Stack>
              </Stack>

              <Stack bgcolor={"#f9f9f9"} p={2}>
                <Typography variant="body2">{replyText}</Typography>
              </Stack>
            </Stack>
          )}

          <div
            ref={followEditableDiv}
            contentEditable
            suppressContentEditableWarning
            onInput={() =>
              setHasContent(
                followEditableDiv.current.innerText.trim().length > 0
              )
            }
            style={{
              border: "none",
              outline: "none",
              minHeight: "50px",
              width: "100%",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              display: "flex",
              alignItems: "center",
              color: hasContent ? "black" : "#aaa" /* Adjust color as needed */,
            }}
            className={!hasContent ? "editable-placeholder" : ""}
          >
            {!hasContent && "Follow up with Theo"}
          </div>

          <Box mt={1}>
            <Button startIcon={<FileUploadIcon />} variant="outlined">
              Add Files
            </Button>
          </Box>
        </Box>
      )}
    </Stack>
  );
}

export default UrlInput;
