export default function Snackbar(theme) {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main, // Set background to primary color
          color: theme.palette.primary.contrastText, // Set text color for contrast
        },
      },
    },
  };
}
